<template>
  <div class="resetPassword">
    <div class="top">
      <van-nav-bar
        title="首次登录修改密码"
        left-text="返回"
        left-arrow
        @click-left="leftIconHandler"
      />
    </div>
    <div class="password">
      <van-form @submit="onSubmit">
        <van-field
          v-model="password"
          name="password"
          placeholder="新密码"
          label="新密码"
          clearable
          :type="passwordType"
          :rules="[
            { required: true, message: '请输入新的密码', trigger: 'onChange' },
            {
              pattern:
                /^(?![a-zA-Z]+$)(?![A-Z0-9]+$)(?![A-Z\W_]+$)(?![a-z0-9]+$)(?![a-z\W_]+$)(?![0-9\W_]+$)[a-zA-Z0-9\W_]{8,16}$/,
              message:
                '密码长度为8-16位，必须包含字母、数字、字符',
              trigger: 'onChange',
            },
            { validator: validatorEmojiText, message: '请勿使用颜文字!', trigger: 'onChange' },

          ]"
          :right-icon="passwordType === 'password' ? 'closed-eye' : 'eye-o'"
          @click-right-icon="rightIconHandler"
        />
        <van-field
          v-model="checkPassword"
          name="checkPassword"
          placeholder="确认密码"
          label="确认密码"
          clearable
          :type="passwordType"
          :rules="[
            { required: true, message: '请输入确认密码', trigger: 'onChange' },
            { validator, message: '两次密码不一致', trigger: 'onChange' },
          ]"
          :right-icon="passwordType === 'password' ? 'closed-eye' : 'eye-o'"
          @click-right-icon="rightIconHandler"
        />
        <p
          style="color: #7f7f7f"
          class="passwordInfo"
        >
          注:密码长度为8-16位，必须包含字母、数字、字符
        </p>
        <div class="button">
          <van-button
            type="info"
            native-type="submit"
            :loading="loading"
          >完成</van-button>
        </div>
      </van-form>
    </div>
  </div>
</template>

<script>
import { firstChangePassword } from '@/api/user'
import { mapMutations } from 'vuex'
import { emojiErrorText } from '@/units/validate'
import { resetRouter } from '@/router/index'

export default {
  name: 'resetPassword',
  data() {
    return {
      password: '',
      checkPassword: '',
      passwordType: 'password',
      loading: false
    }
  },
  methods: {
    ...mapMutations('user', ['removeTokenInfo', 'removeUserProfile']),
    //  校验密码是否一致
    validator() {
      return this.password === this.checkPassword
    },
    // 校验是否含有颜文字
    validatorEmojiText(val) {
      return emojiErrorText(val)
    },
    // 提交修改密码
    async onSubmit(values) {
      this.loading = true
      try {
        await firstChangePassword({ password: this.password })
        this.loading = false
        this.removeTokenInfo()
        this.removeUserProfile()

        resetRouter()
        this.$toast.success({
          message: '密码修改成功!',
          duration: 500
        })
        this.$router.push('/accountLogin')
      } catch (error) {
        this.loading = false
        this.$toast.fail({
          message: '密码修改失败!',
          duration: 500
        })
      }
    },
    // 切换是否密文展示
    rightIconHandler() {
      if (this.passwordType === 'password') {
        this.passwordType = ''
      } else {
        this.passwordType = 'password'
      }
    },
    // 返回按钮事件
    leftIconHandler() {
      this.removeTokenInfo()
      this.removeUserProfile()
      resetRouter()
      this.$router.back()
    }
  }
}
</script>

<style lang="scss" scoped>
.resetPassword {
  ::v-deep {
    .password {
      .button {
        margin-top: 150px;
        text-align: center;

        .van-button--info {
          height: 36px;
          width: 95%;
          border-radius: 5px;
        }
      }

      .passwordInfo {
        padding-top: 5px;
        text-align: center;
        font-size: 12px;
      }
    }
  }
}
</style>
