var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"resetPassword"},[_c('div',{staticClass:"top"},[_c('van-nav-bar',{attrs:{"title":"首次登录修改密码","left-text":"返回","left-arrow":""},on:{"click-left":_vm.leftIconHandler}})],1),_c('div',{staticClass:"password"},[_c('van-form',{on:{"submit":_vm.onSubmit}},[_c('van-field',{attrs:{"name":"password","placeholder":"新密码","label":"新密码","clearable":"","type":_vm.passwordType,"rules":[
          { required: true, message: '请输入新的密码', trigger: 'onChange' },
          {
            pattern:
              /^(?![a-zA-Z]+$)(?![A-Z0-9]+$)(?![A-Z\W_]+$)(?![a-z0-9]+$)(?![a-z\W_]+$)(?![0-9\W_]+$)[a-zA-Z0-9\W_]{8,16}$/,
            message:
              '密码长度为8-16位，必须包含字母、数字、字符',
            trigger: 'onChange',
          },
          { validator: _vm.validatorEmojiText, message: '请勿使用颜文字!', trigger: 'onChange' },

        ],"right-icon":_vm.passwordType === 'password' ? 'closed-eye' : 'eye-o'},on:{"click-right-icon":_vm.rightIconHandler},model:{value:(_vm.password),callback:function ($$v) {_vm.password=$$v},expression:"password"}}),_c('van-field',{attrs:{"name":"checkPassword","placeholder":"确认密码","label":"确认密码","clearable":"","type":_vm.passwordType,"rules":[
          { required: true, message: '请输入确认密码', trigger: 'onChange' },
          { validator: _vm.validator, message: '两次密码不一致', trigger: 'onChange' },
        ],"right-icon":_vm.passwordType === 'password' ? 'closed-eye' : 'eye-o'},on:{"click-right-icon":_vm.rightIconHandler},model:{value:(_vm.checkPassword),callback:function ($$v) {_vm.checkPassword=$$v},expression:"checkPassword"}}),_c('p',{staticClass:"passwordInfo",staticStyle:{"color":"#7f7f7f"}},[_vm._v(" 注:密码长度为8-16位，必须包含字母、数字、字符 ")]),_c('div',{staticClass:"button"},[_c('van-button',{attrs:{"type":"info","native-type":"submit","loading":_vm.loading}},[_vm._v("完成")])],1)],1)],1)])
}
var staticRenderFns = []

export { render, staticRenderFns }